import React, { useState } from "react";
import { Flex, FilterButton } from "@badi/badi-components";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { CATEGORIES, DEFAULT_CATEGORY_VALUE } from "datamodel/Inbox/constants";
import Select from "components/form-select";
import { FilterWrapper } from "./styled-components";

const Header = ({
  disabled,
  category,
  onChangeCategory,
  onFilterClick,
  onCancelFilter,
  roomTitle = null,
  showFilterRoom,
}) => {
  const categories = translate(CATEGORIES, "label");

  return (
    <Flex direction="column" gutter={3}>
      <Flex
        direction="column"
        gutter={2}
        sm={{
          direction: "row",
          justify: "spaceBetween",
          gutter: 0,
        }}
      >
        <Flex alignItems="center" gutter={1}>
          <FilterWrapper>
            <Select
              disabled={disabled}
              initialValue={category || DEFAULT_CATEGORY_VALUE}
              name="categories"
              onChange={({ value }) => onChangeCategory(value)}
              options={categories}
            />
          </FilterWrapper>
          {showFilterRoom && (
            <FilterButton
              icon={getPublicAsset("icons/bed_16.svg")}
              id="filter_by_room"
              onCancelFilter={onCancelFilter}
              onFilterClick={onFilterClick}
              title={translate("inbox.dropdown_all")}
              value={roomTitle}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
