import { get } from "radash";

const mapToModel = (model, props) => {
  const result = Object.keys(model).map((key) => {
    const property = model[key];
    if (typeof property == "string") {
      return { [key]: get(props, property) };
    }
    if (typeof property == "function") {
      return { [key]: property(props) };
    }
    if (typeof property == "object") {
      return { [key]: mapToModel(property, props) };
    }
  });
  return Object.assign(...result);
};

export default mapToModel;
