import React from "react";
import styled from "styled-components";
import UserFeedback from "base/shared/user-feedback";
import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import Image from "components/Image";
import { Heading } from "@badi/badi-components";
import { Button, Text } from "app/badi-components";
import { translate } from "base/shared/Localization";

const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
    justify-content: space-evenly;
  `,
  Image: styled(Image)`
    max-width: 200px;
  `,
  Heading: styled(Heading)`
    width: min-content;
    white-space: break-space;
    text-align: center;
  `,
  Text: styled(Text)`
    width: 60%;
    text-align: center;
  `,
  Button: styled(Button)`
    width: 60%;
  `,
};

const closeModal = () => {
  modal.remove(MODAL_ID.SWIPING_REQUEST_ACCEPTED);
};

const RequestAccepted = () => (
  <Components.Container>
    <Components.Heading>
      {translate("inbox-lister.request-accepted.title")}
    </Components.Heading>
    <Components.Text>
      {translate("inbox-lister.request-accepted.subtitle")}
    </Components.Text>
    <Components.Image
      alt="Your request has been accepted"
      src="/assets/images/swiping/request-accepted.svg"
    />
    <Components.Button onClick={closeModal}>
      {translate("inbox-lister.request-accepted.cta")}
    </Components.Button>
  </Components.Container>
);

const openRequestAcceptedModal = () => {
  try {
    modal.add(<RequestAccepted />, {
      id: MODAL_ID.SWIPING_REQUEST_ACCEPTED,
      type: MODAL_TYPE.DEFAULT,
      onClose: () => closeModal(),
    });
  } catch (e) {
    UserFeedback.exception("Something went wrong", e.message, e);
  }
};

export default openRequestAcceptedModal;
