import React, { useEffect } from "react";

import RoomSelector from "components/room-selector";

import { getFilteredInboxRoute } from "./room-selector-page-route";

const RoomSelectorPage = ({
  roomId = null,
  getRooms,
  rooms = null,
  router,
  category,
  setInboxRoom,
  currentPage = 1,
  totalPages = 1,
  isSwipingToggleActive,
}) => {
  useEffect(() => {
    getRooms({ per: 10 });
  }, []);

  const handleOnClose = () => {
    router.push(getFilteredInboxRoute({ category, isSwipingToggleActive }));
  };

  const handleChangePage = (nextPage) => {
    return getRooms({ page: nextPage, per: 10 });
  };

  const handleRoomSelected = (e) => {
    const room = Number(e.target.value);
    const selectedRoom = rooms.filter(({ id }) => id === room)[0];
    setInboxRoom(selectedRoom);
    router.push(
      getFilteredInboxRoute({ category, room, isSwipingToggleActive }),
    );
  };

  return (
    <>
      {rooms && (
        <RoomSelector
          currentPage={currentPage}
          onClose={handleOnClose}
          onPageChange={handleChangePage}
          onSelect={handleRoomSelected}
          roomId={roomId}
          roomItems={rooms}
          totalPages={totalPages}
        />
      )}
    </>
  );
};

export default RoomSelectorPage;
