import { BADI_COLORS } from "app/badi-components";
import styled from "styled-components";

const Tag = styled.div`
  display: inline-block;
  margin: 4px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 0.93rem;
  font-weight: 450;
  text-align: center;
  background-color: transparent;
  border: solid 1px ${BADI_COLORS.SEMANTIC.INFO.DEFAULT};
  color: ${BADI_COLORS.SEMANTIC.INFO.DEFAULT};
`;

export default Tag;
