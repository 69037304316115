import React from "react";

const LoadingSpinner = ({ color, style }) => (
  <svg
    fill={color}
    style={{ width: "34px", height: "34px", ...style }}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    className="loading-spinner"
  >
    <rect x="46.5" y="40" width="7" height="20" transform="translate(0 -30)">
      <animate
        attributeName="opacity"
        from="0"
        to="1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      transform="rotate(45 86.213 65)"
    >
      <animate
        attributeName="opacity"
        from="0"
        to="1"
        dur="1s"
        begin="0.125s"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="46.5" y="40" width="7" height="20" transform="rotate(90 65 65)">
      <animate
        attributeName="opacity"
        from="0"
        to="1"
        dur="1s"
        begin="0.25s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      transform="rotate(135 56.213 65)"
    >
      <animate
        attributeName="opacity"
        from="0"
        to="1"
        dur="1s"
        begin="0.375s"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="46.5" y="40" width="7" height="20" transform="rotate(180 50 65)">
      <animate
        attributeName="opacity"
        from="0"
        to="1"
        dur="1s"
        begin="0.5s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      transform="rotate(-135 43.787 65)"
    >
      <animate
        attributeName="opacity"
        from="0"
        to="1"
        dur="1s"
        begin="0.625s"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="46.5" y="40" width="7" height="20" transform="rotate(-90 35 65)">
      <animate
        attributeName="opacity"
        from="0"
        to="1"
        dur="1s"
        begin="0.75s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46.5"
      y="40"
      width="7"
      height="20"
      transform="rotate(-45 13.787 65)"
    >
      <animate
        attributeName="opacity"
        from="0"
        to="1"
        dur="1s"
        begin="0.875s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
);

LoadingSpinner.defaultProps = {
  color: "#fff",
  style: {},
};

export default LoadingSpinner;
