import React from "react";
import { useSelector } from "react-redux";

import { BREAKPOINTS_PX, useMatchMedia } from "@badi/badi-components";

import { translate } from "base/shared/Localization";
import { getIsDesktop } from "datamodel/Device/selectors";
import { getInboxPath } from "Sections/inbox/routes";
import { getNewRoomPath, getMyListingsPath } from "Sections/Room/routes";
import { getOverviewPath } from "Sections/overview/routes";
import { getListerMetricsPath } from "Sections/analytics/rooms/routes";

import TopBar from "../top-bar-base";
import Element from "../topbar-element";
import Menu from "./menu";
import Logo from "../Logo/Logo";
import SearchBar from "../search-bar";
import UserMenu from "../user-menu";
import Search from "../Search";

export const QUERIES = [`(min-width: ${BREAKPOINTS_PX.lg})`];

const ListerTopBar = ({
  city = null,
  logout,
  messages,
  picture,
  switchUser,
  withNavigationWrapper = true,
  isSwipingToggleActive = false,
}) => {
  const desktopDevice = useSelector(getIsDesktop);
  const [isDesktop] = useMatchMedia(QUERIES, [desktopDevice]);
  const switchToSeeker = () => switchUser("seeker");

  return (
    <TopBar
      dataQa="topbar-lister"
      withNavigationWrapper={withNavigationWrapper}
    >
      {isDesktop ? (
        <>
          <Logo dataQa="topbar-logo" lister={true} />
          <SearchBar dataQa="topbar-search-bar" initialValue={city} />
          <Element
            dataQa="topbar-lister-menu-list"
            position="right"
            to={getNewRoomPath()}
          >
            {translate("topbar.element.rentroom.title")}
          </Element>
          {!isSwipingToggleActive && (
            <Element
              dataQa="topbar-lister-menu-analytics"
              name="topbar_lister_analytics"
              position="right"
              to={getListerMetricsPath()}
            >
              {translate("topbar.element.analytics_title")}
            </Element>
          )}
          <Element
            dataQa="topbar-lister-menu-overview"
            name="topbar_lister_overview"
            position="right"
            to={getOverviewPath()}
          >
            {translate("topbar.element.overview.title")}
          </Element>
          <Element
            dataQa="topbar-lister-menu-listings"
            name="topbar_lister_listings"
            position="right"
            to={getMyListingsPath()}
          >
            {translate("topbar.element.mylistings.title")}
          </Element>
          <Element
            dataQa="topbar-lister-menu-inbox"
            notifications={messages}
            position="right"
            to={getInboxPath()}
          >
            {translate("topbar.element.inbox.title")}
          </Element>
          <Element
            dataQa="topbar-lister-menu-help"
            position="right"
            to={translate("sitemap.faqs.new.link")}
          >
            {translate("topbar.element.help.title")}
          </Element>
          <Element position="right">
            <UserMenu
              data-qa="topbar-lister-menu"
              logout={logout}
              mode="lister"
              picture={picture}
              switchMode={switchToSeeker}
            />
          </Element>
        </>
      ) : (
        <>
          <Menu
            logout={logout}
            messages={messages}
            onSwitchUser={switchToSeeker}
            isSwipingEnabled={isSwipingToggleActive}
          />
          <Logo dataQa="topbar-logo" lister={true} />
          <Search dataQa="topbar-search-button" initialValue={city} />
        </>
      )}
    </TopBar>
  );
};

export default ListerTopBar;
