import React, { useCallback } from "react";
import Footer from "components/Layout/Footer";
import LayoutWrapper from "components/Layout/Wrapper";
import { Header } from "./components/";
import { getPathToRequests, getPathToChats, SUBPATHS } from "./routes";
import styled from "styled-components";
import { mapToModel } from "./utils";
import { parseConnections } from "datamodel/Inbox/utils";
import { fetchDataForInbox } from "./hooks";
import { BADI_COLORS, COLORS } from "app/badi-components";
import { Link } from "react-router";
import { InboxListerModel as model } from "./models";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { acceptEnquiry } from "app/datamodel/Conversation/api";
import { openRequestAcceptedModal } from "./modals";
import matchPaths from "./utils/matchPaths";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 200px;
  margin-top: 20px;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 4px solid ${COLORS.NEUTRAL_10};
  width: 50%;
`;

Nav.Link = styled(Link)`
  ${({ emphasised }) => `font-weight: ${emphasised ? "bold" : "normal"};`}
  padding: 1px;
  padding-bottom: 10px;
  :hover {
    text-decoration: none;
    box-shadow: 0 0 0 4px ${BADI_COLORS.BRAND.PRIMARY.DEFAULT};
    clip-path: inset(0px 1px -5px 1px);
  }
`;

Nav.Icon = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-left: 10px;
`;

const getFirstRequest = (requests) => ({
  currentRequest: requests[0],
  idOfPreviousRequest: null,
  idOfNextRequest: requests[1]?.id,
});

const findRequestWithId = (requests, id) => {
  if (!id) {
    return;
  }
  const currentRequestIndex = requests.findIndex((request) => request.id == id);
  const currentRequest = requests[currentRequestIndex];
  return {
    currentRequest,
    idOfPreviousRequest: requests[currentRequestIndex - 1]?.id,
    idOfNextRequest: requests[currentRequestIndex + 1]?.id,
  };
};

const getPathToRequest = (id) => {
  if (!id) {
    return null;
  }
  return getPathToRequests(id);
};

const InboxLister = ({
  params: { id },
  location,
  router,
  token,
  reload,
  children,
}) => {
  const { room: roomId, category, page } = location.query;
  const { isLoaded, requests, chats, pagination } = fetchDataForInbox(
    token,
    {
      roomId,
      category,
    },
    { page },
  );
  const requestsModel = isLoaded
    ? requests.data.map((request) => mapToModel(model, request))
    : [];
  const chatsModel = isLoaded ? parseConnections(chats.data) : [];

  const { currentRequest, idOfPreviousRequest, idOfNextRequest } =
    findRequestWithId(requestsModel, id) || getFirstRequest(requestsModel);

  const requestsCount = requestsModel.length;
  const chatsCount = chatsModel.length;

  const onClickAcceptConnection = () => {
    acceptEnquiry({ token, connectionId: currentRequest.id }).then(() => {
      openRequestAcceptedModal();
      reload();
    });
  };

  const matches = matchPaths(
    [SUBPATHS.REQUESTS, SUBPATHS.CHATS, SUBPATHS.CONVERSATIONS],
    location.pathname,
    { default: SUBPATHS.REQUESTS },
  );

  const requestsText = Number.isInteger(requestsCount)
    ? translate("inbox-lister.nav-bar.requests.title-with-count", {
        count: requestsCount,
      })
    : translate("inbox-lister.nav-bar.requests.title");
  const chatsText = Number.isInteger(chatsCount)
    ? translate("inbox-lister.nav-bar.chats.title-with-count", {
        count: chatsCount,
      })
    : translate("inbox-lister.nav-bar.chats.title");

  const childProps = {
    connectionId: id,
    connections: chatsModel,
    location,
    router,
    pagination,
    currentRequest,
    pathToNextRequest: getPathToRequest(idOfNextRequest),
    pathToPreviousRequest: getPathToRequest(idOfPreviousRequest),
    onClickAcceptConnection,
  };

  return (
    <LayoutWrapper footer={<Footer campaign="inbox_footer" />}>
      <Container>
        <Header>Inbox</Header>
        <Nav>
          <Nav.Link
            emphasised={matches[SUBPATHS.REQUESTS]}
            to={getPathToRequests()}
            decoration="none"
          >
            {requestsText}
            <Nav.Icon src={getPublicAsset("icons/requests.svg")} />
          </Nav.Link>
          <Nav.Link
            emphasised={
              matches[SUBPATHS.CHATS] || matches[SUBPATHS.CONVERSATIONS]
            }
            to={getPathToChats()}
          >
            {chatsText}
            <Nav.Icon src={getPublicAsset("icons/chats.svg")} />
          </Nav.Link>
        </Nav>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, childProps),
        )}
      </Container>
    </LayoutWrapper>
  );
};

export default InboxLister;
