import React from "react";
import { connect } from "react-redux";

// A higher-order component to try to clarify rendering rules at the routing layer.
// Typical use case:
// <Route
//   path="/conversation/:id"
//   components={Ternary([isV2FlagOnSelector], ConversationV2, ConversationV1)}
// />

const Ternary = (selectors, TrueComponent, FalseComponent) => {
  const mapStateToProps = (state) => ({
    isTrue: !selectors.some((selector) => !selector(state)),
  });
  const Component = (props) => {
    const { isTrue } = props;
    return isTrue ? (
      <TrueComponent {...props} />
    ) : (
      <FalseComponent {...props} />
    );
  };
  Component.displayName = `Ternary(${TrueComponent.displayName} || ${FalseComponent.displayName})`;
  return connect(mapStateToProps)(Component);
};

export default Ternary;
