import { CONNECTION_STATUS_CODE } from "datamodel/Conversation/constants";

export const CATEGORIES = [
  { value: "booking", label: "inbox.filter.categories.booking" },
  { value: "default", label: "inbox.filter.categories.default" },
  { value: "pending", label: "inbox.filter.categories.pending" },
  { value: "messaging", label: "inbox.filter.categories.messaging" },
  { value: "closed", label: "inbox.filter.categories.closed" },
];

export const DEFAULT_CATEGORY_VALUE = CATEGORIES[1].value;

export const HIGHEST_PRIORITY_STATUSES = [
  CONNECTION_STATUS_CODE.BOOKING_REQUEST_PENDING,
  CONNECTION_STATUS_CODE.BOOKING_REQUEST_PAYMENT_FAILED,
];

export const CURRENT_USER_ROLES = {
  LISTER: "lister",
  SEEKER: "seeker",
};
