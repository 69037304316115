import { connect } from "react-redux";
import { getUserRole } from "datamodel/User/selectors";
import { getToken, getUserId } from "datamodel/Auth/selectors";

import ChatsBrowser from "./chats-browser";

const mapStateToProps = (state) => ({
  userId: getUserId(state),
  token: getToken(state),
  userRole: getUserRole(state),
});

export default connect(mapStateToProps)(ChatsBrowser);
