import React from "react";
import { stringify } from "qs";
import ChatsBrowser from "./chats-browser-mapping";
import { SUBPATHS } from "../routes";
import { getRelativePath } from "base/shared/Utils";

const ChatsBrowserRoute = ({ location, router, ...rest }) => {
  const { query = {} } = location;
  const { category, room } = query;
  const filters = {
    room,
    category,
  };

  const setFilter = (newFilter = {}) => {
    const queryStringParameters = {
      ...query,
      ...newFilter,
      fromFilter: true,
    };
    const queryString = stringify(queryStringParameters, {
      addQueryPrefix: true,
    });
    router.push({
      pathname: getRelativePath(`/inbox/${SUBPATHS.CHATS}`),
      search: queryString,
    });
  };

  const setPagination = (newPagination = {}) => {
    const queryStringParameters = { ...query, ...newPagination };
    const queryString = stringify(queryStringParameters, {
      addQueryPrefix: true,
    });
    router.push({
      pathname: getRelativePath(`/inbox/${SUBPATHS.CHATS}`),
      search: queryString,
    });
  };

  return (
    <ChatsBrowser
      filters={filters}
      setFilter={setFilter}
      setPagination={setPagination}
      category={category}
      router={router}
      {...rest}
    />
  );
};

export default ChatsBrowserRoute;
