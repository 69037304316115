import { createSelector } from "reselect";

import {
  DEFAULT_USER_ROLE,
  LEGAL_RESPONSE,
  LEGAL_STATUS,
  USER_STATUS,
  LISTER_USER_ROLE,
} from "./constants";

export const getUserFromState = (state) => state.User;

export const getUserLegal = createSelector(
  getUserFromState,
  (user) => user.legal,
);

export const getUserLoginType = createSelector(
  getUserFromState,
  (user) => user.loginType,
);

export const getUserId = createSelector(getUserFromState, (user) => user.id);

// * ghost: it means that the user doesn't have a completed profile
export const getGhost = createSelector(getUserFromState, (user) => user.ghost);

export const getUserConfirmation = (state, type) =>
  getUserFromState(state).confirmations[type] || false;

export const getUserRole = createSelector(getUserFromState, ({ mode }) =>
  mode === "default" ? DEFAULT_USER_ROLE : mode,
);

export const getIsUserInListerMode = createSelector(
  getUserRole,
  (mode) => mode == LISTER_USER_ROLE,
);

export const getTermsAndConditions = createSelector(
  getUserLegal,
  () => LEGAL_RESPONSE.ACCEPTED,
);

export const getMarketingConsent = createSelector(
  getUserLegal,
  (legal) => legal.marketingConsent,
);

export const getProfilingConsent = createSelector(
  getUserLegal,
  (legal) => legal.profilingConsent,
);

export const getVideocallConsent = createSelector(
  getUserLegal,
  (legal) => legal.videocallConsent,
);

export const getUserStatus = createSelector(
  [getUserId, getGhost],
  (id, ghost) => {
    if (id) {
      return ghost
        ? USER_STATUS.PROFILE_INCOMPLETE
        : USER_STATUS.PROFILE_COMPLETE;
    }
    return USER_STATUS.UNREGISTERED;
  },
);

export const getIsUserListerSelector = createSelector(
  getUserFromState,
  (user) => !!user.lister,
);

export const loginTypeSelector = createSelector(
  getUserFromState,
  (user) => user.loginType,
);

export const latestTosConsentSelector = createSelector(
  getUserLegal,
  (legal) => legal.latestTosConsent,
);
export const privacyConsentSelector = createSelector(
  getUserLegal,
  (legal) => legal.privacyConsent,
);

export const consentListSelector = createSelector(
  getUserLegal,
  (legal) => legal.consentList,
);

export const isLegalLoadedSelector = createSelector(
  getUserFromState,
  (user) => user.legalStatus === LEGAL_STATUS.LOADED,
);

export const isGdprAnsweredSelector = createSelector(
  consentListSelector,
  (consentList) => consentList.length === 0,
);

export const getNameSelector = createSelector(
  getUserFromState,
  (user) => user.name,
);

export const getSurnameSelector = createSelector(
  getUserFromState,
  (user) => user.lastName,
);

export const getBirthdateSelector = createSelector(
  getUserFromState,
  (user) => user.birthDate,
);

export const getGenderSelector = createSelector(
  getUserFromState,
  (user) => user.gender,
);

export const getOccupationStatusSelector = createSelector(
  getUserFromState,
  (user) => user.workStudiesStatus,
);

export const getPicturesSelector = createSelector(
  getUserFromState,
  (user) => user.pictures,
);

export const seekerPreferencesRequiredSelector = createSelector(
  getUserFromState,
  (user) => user.seekerPreferencesRequired,
);

export const phoneConfirmationSelector = createSelector(
  getUserFromState,
  ({ confirmations }) => confirmations.phone,
);

export const getUserFetchedSelector = createSelector(
  getUserFromState,
  (user) => user.fetched,
);

export const getDefaultPicture = createSelector(
  getUserFromState,
  (user) => user.mainPicture,
);

export const onboardingRoleSelector = createSelector(
  getUserFromState,
  (user) => user.onboarding.role,
);

export const getIsUserWithFastTrack = createSelector(getUserFromState, (user) =>
  user.activeUserPlans?.includes("fast_track"),
);
