import { objectify } from "radash";

const matchPaths = (patterns, path, options = {}) => {
  const { default: defaultOption } = options;
  const result = objectify(
    patterns,
    (pattern) => pattern,
    (pattern) => new RegExp(pattern).test(path),
  );
  if (!defaultOption || Object.values(result).some((value) => value)) {
    return result;
  }
  return { ...result, [defaultOption]: true };
};

export default matchPaths;
