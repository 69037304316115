import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { Flex, BREAKPOINTS_PX, useMatchMedia } from "@badi/badi-components";
import styled from "styled-components";

import ConnectionDetails from "./components/connection-details";

import ChatHeader from "./components/header";
import ChatInput from "./components/input";
import MessageList from "./components/message-list";
import { Root, ContentWrapper } from "./styled-components";

const Chat = ({
  connection,
  connectionId,
  getConnectionDetails,
  resetInboxConnection,
  token,
  connectionStatus,
}) => {
  const [wideScreen] = useMatchMedia([`(min-width: ${BREAKPOINTS_PX.lg})`]);

  useEffect(() => {
    getConnectionDetails(connectionId);

    return resetInboxConnection;
  }, []);

  if (!connection.id) {
    return null;
  }

  const {
    allowedActions,
    room,
    user,
    status: { statusDetail } = {},
  } = connection;

  return (
    <>
      <ChatHeader
        connection={connection}
        detailsButtonVisible={!wideScreen}
        text={statusDetail}
      />
      <Container>
        <Flex as={Root} gutter={4}>
          <ContentWrapper grow={1}>
            <MessageList connectionId={connectionId} />
            <ChatInput otherUserName={user.name} />
          </ContentWrapper>
          {wideScreen && (
            <ConnectionDetails
              allowedActions={allowedActions}
              connectionId={connectionId}
              isInvite={false}
              room={room}
              shrink={0}
              token={token}
              user={user}
              status={connectionStatus}
            />
          )}
        </Flex>
      </Container>
    </>
  );
};

export default Chat;
