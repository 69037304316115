import { getRelativePath } from "base/shared/Utils";
import { stringify, parse } from "qs";

export const getFilteredInboxRoute = ({
  category,
  room,
  search = window.location.search,
  isSwipingToggleActive = false,
} = {}) => {
  const basePath = isSwipingToggleActive ? "/inbox/chats" : "/inbox";
  const existingQueryParameters = parse(search, { ignoreQueryPrefix: true });
  const queryParameters = {
    ...existingQueryParameters,
    category,
    room,
    fromFilter: true,
  };
  const query = stringify(queryParameters);
  return getRelativePath(`${basePath}?${query}`);
};
