import { getDisplayPrice } from "base/shared/Utils";
import { getStayMonths } from "datamodel/Room/utils";
import {
  getStudies,
  getUserLanguages,
  getVerificationLevel,
  getProfession,
} from "base/shared/User";
import { SEEKER_USER_ROLE, LISTER_USER_ROLE } from "datamodel/User/constants";
import VisitModel from "datamodel/visits/models/visit-model";
import { ANNOTATION_ACTION_TYPE_LINK } from "./constants";

export function getOccupation(data = {}) {
  const action = data.main_occupation === 1 ? "studies" : "works";

  const occupation =
    action === "studies"
      ? getStudies(data.pursued_level_of_study)
      : getProfession(data.work_industry);

  return {
    action,
    occupation: occupation ? occupation.title : "",
  };
}

const getUserMetrics = ({
  bookings,
  last_connection: lastConnection,
  seeker_enquiries_response_ratio: responseRatio,
  member_since: memberSince,
} = {}) => ({
  bookings,
  lastConnection,
  responseRatio,
  memberSince,
});

export function mergeMessages(oldMessages, newMessages) {
  const messages = oldMessages.concat(
    newMessages.filter((newMessage) => {
      return !oldMessages.find((oldMessage) => oldMessage.id === newMessage.id);
    }),
  );

  return messages;
}

export function parseRoom(data) {
  const picture = data.cover_picture ? data.cover_picture.width_100_url : null;

  return {
    id: data.id,
    title: data.title,
    address: data.address,
    picture,
    availableFrom: data.available_from,
    publishedAt: data.published_at,
    matchesStatusLabel: data.matches_status_label,
    minStay: getStayMonths(data.min_days),
    premiumPlan: data.premium_plan,
    price: getDisplayPrice(
      data.monthly_price.price,
      data.monthly_price.currency,
    ),
    billsIncluded: data.monthly_price.bills_included,
    owned: data.owned,
    virtualTourLink: data.virtual_tour_link || null,
    deposit: data.deposit,
    activeConnectionsCount: data.active_connections_count,
    visitsProposed: data.visits_proposed,
    externalAgencyId: data.agency_external_id,
  };
}

export function parseStatus(data) {
  return {
    status: data.status,
    statusLabel: data.status_label,
    statusLevel: data.status_level,
    statusBadgeLabel: data.status_badge_label,
    statusDetail: data.status_detail,
  };
}

export function parseUser(data, isOwner) {
  const avatar = data.cover_picture ? data.cover_picture.width_100_url : null;

  return {
    id: data.id,
    age: data.age,
    avatar,
    badge: getVerificationLevel(data.badges),
    languages: getUserLanguages(data.languages_by_affinity),
    metrics: getUserMetrics(data.metrics),
    name: data.first_name,
    occupation: getOccupation(data.occupation_detail),
    pictures: data.pictures,
    role: isOwner ? LISTER_USER_ROLE : SEEKER_USER_ROLE,
    avatarBadge: data.avatar_badge,
  };
}

export function parseConnectionData(data) {
  return {
    id: data.id,
    allowedActions: { ...data.allowed_actions },
    actions: data.actions,
    room: parseRoom(data.room),
    status: parseStatus(data),
    // * We are setting the other user, so we have to negate the owned field
    user: parseUser(data.other_user, !data.room.owned),
    visits: new VisitModel(data.visit_data).toJSON(),
    bookingRequestId: data.booking_request_id,
    bookingReferenceId: data.booking_reference,
  };
}

export function parseConnectionEnquiry({
  action,
  message,
  no_message_behavior: noMessageBehavoir,
  pay_to_match_required: payToMatchRequired,
  room,
  user,
  visit_requested: visitRequested,
}) {
  return {
    action,
    message,
    noMessageBehavoir,
    payToMatchRequired,
    room: parseRoom(room),
    user: parseUser(user, !room.owned),
    visitRequested,
  };
}

function parseAnnotations(annotations, messageId) {
  return annotations.map((annotation, i) => ({
    content: annotation.content,
    id: +`${messageId}${i}`,
    link: annotation.link,
    type: annotation.annotation_type,
    actionType: annotation.action_type || ANNOTATION_ACTION_TYPE_LINK,
    linkText: annotation.popup_key_text || annotation.link_text,
    popupKey: annotation.popup_key || null,
    heading: annotation.heading || "",
  }));
}

function parseTextMessage(data) {
  return {
    annotations: parseAnnotations(data.annotations, data.id),
    date: data.sent_at_in_words,
    id: data.id,
    content: data.body.attributes.content,
    type: data.body.type,
    user: {
      avatar: data.body.attributes.user.cover_picture
        ? data.body.attributes.user.cover_picture.width_100_url
        : null,
      id: data.body.attributes.user.id,
      name: data.body.attributes.user.first_name,
    },
  };
}

function parsePictureMessage(data) {
  return {
    annotations: parseAnnotations(data.annotations),
    date: data.sent_at_in_words,
    id: data.id,
    url: data.body.attributes.url,
    type: data.body.type,
    user: {
      avatar: data.body.attributes.user.cover_picture
        ? data.body.attributes.user.cover_picture.width_100_url
        : null,
      id: data.body.attributes.user.id,
      name: data.body.attributes.user.first_name,
    },
  };
}

export function parseSystemMessage(data) {
  return {
    annotations: parseAnnotations(data.annotations),
    date: data.sent_at_in_words,
    id: data.id,
    content: data.body.attributes.content,
    type: data.body.type,
    primaryAction: data.body.attributes.primary_action,
  };
}

export function parseMessage(message) {
  switch (message.body.type) {
    case "text":
      return parseTextMessage(message);
    case "picture":
      return parsePictureMessage(message);
    case "system":
      return parseSystemMessage(message);
    default:
      return message;
  }
}

export function parseMessages(messages) {
  return messages.map((message) => parseMessage(message));
}

export function parsePagination(data) {
  const { current_page: currentPage, total_pages: totalPages, total } = data;

  return {
    currentPage,
    totalPages,
    total,
  };
}
