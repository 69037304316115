import React from "react";
import { translate } from "base/shared/Localization";
import { getInboxPath } from "Sections/inbox/routes";
import { getNewRoomPath, getMyListingsPath } from "Sections/Room/routes";
import { getMyProfilePath } from "Sections/user-profile/routes";
import { getPayoutSettingsPath } from "Sections/payout/routes";
import { getSettingsPath } from "Sections/Settings/routes";
import { getListerMetricsPath } from "Sections/analytics/rooms/routes";
import { getOverviewPath } from "Sections/overview/routes";

import Menu, {
  MenuItemButton,
  MenuItemLink,
  MenuItemSeparator,
} from "../reponsive-menu";

const ListerMenu = ({
  logout,
  messages,
  onSwitchUser,
  onListRoomClick,
  onAnalyticsClick,
  isSwipingEnabled,
}) => {
  return (
    <Menu>
      <MenuItemLink
        dataQa="topbar-lister-menu-list"
        onClick={onListRoomClick}
        to={getNewRoomPath()}
      >
        {translate("topbar.element.rentroom.title")}
      </MenuItemLink>
      {!isSwipingEnabled && (
        <MenuItemLink
          dataQa="topbar-lister-menu-analytics"
          onClick={onAnalyticsClick}
          to={getListerMetricsPath()}
        >
          {translate("topbar.element.analytics_title")}
        </MenuItemLink>
      )}
      <MenuItemLink dataQa="topbar-lister-menu-overview" to={getOverviewPath()}>
        {translate("topbar.element.overview.title")}
      </MenuItemLink>
      <MenuItemLink
        dataQa="topbar-lister-menu-listings"
        to={getMyListingsPath()}
      >
        {translate("topbar.element.mylistings.title")}
      </MenuItemLink>
      <MenuItemLink
        dataQa="topbar-lister-menu-inbox"
        highlight={messages}
        to={getInboxPath()}
      >
        {translate("topbar.element.inbox.title")}
      </MenuItemLink>
      <MenuItemSeparator />
      <MenuItemLink dataQa="topbar-lister-menu-profile" to={getMyProfilePath()}>
        {translate("topbar.element.profile.title")}
      </MenuItemLink>
      <MenuItemLink dataQa="topbar-lister-menu-settings" to={getSettingsPath()}>
        {translate("topbar.element.settings.title")}
      </MenuItemLink>
      <MenuItemLink
        dataQa="topbar-lister-menu-booking-settings"
        to={getPayoutSettingsPath()}
      >
        {translate("topbar.element.booking.title")}
      </MenuItemLink>
      <MenuItemButton
        dataQa="topbar-lister-menu-switch"
        imageSrc="/assets/icons/switch.svg"
        onClick={onSwitchUser}
      >
        {translate("topbar.element.switchseeker.title")}
      </MenuItemButton>
      <MenuItemSeparator />
      <MenuItemLink
        dataQa="topbar-lister-menu-download"
        external={true}
        to="https://app.adjust.com/drpyca?campaign=mobileweb_menu&adgroup=link&creative=link_1"
      >
        {translate("topbar.element.download.title")}
      </MenuItemLink>
      <MenuItemButton
        dataQa="topbar-lister-menu-help"
        to={translate("sitemap.faqs.new.link")}
      >
        {translate("topbar.element.help.title")}
      </MenuItemButton>
      <MenuItemButton dataQa="topbar-lister-menu-logout" onClick={logout}>
        {translate("topbar.element.logout.title")}
      </MenuItemButton>
    </Menu>
  );
};

export default ListerMenu;
