import { getRelativePath } from "base/shared/Utils";

export const SUBPATHS = {
  CHATS: "chats",
  REQUESTS: "requests",
  CONVERSATIONS: "conversation",
};

export const getPathToChats = (queryString = window.location.search) => {
  return getRelativePath(`/inbox/${SUBPATHS.CHATS}${queryString}`);
};

export const getPathToRequests = (id) => {
  if (id) {
    return getRelativePath(
      `/inbox/${SUBPATHS.REQUESTS}/${id}/${window.location.search}`,
    );
  }
  return getRelativePath(
    `/inbox/${SUBPATHS.REQUESTS}${window.location.search}`,
  );
};
