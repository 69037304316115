import { connect } from "react-redux";

import OverviewActions from "datamodel/Overview/actions";
import TransactionsActions from "datamodel/Transactions/actions";
import UserActions from "datamodel/User/actions";
import {
  getFetchedSummary,
  getOverviewSections,
  getOverviewheading,
  getUserListerScore,
  getUserPicture,
} from "datamodel/Overview/selectors";
import { getTransactionsSummarySelector } from "datamodel/Transactions/selectors";
import { isPremiumEnabledSelector } from "modules/premium/selectors";
import { getPendingReviewsAction } from "datamodel/reviews/actions";
import { getToken } from "datamodel/Auth/selectors";
import { getPendingReviewsSelector } from "datamodel/reviews/selectors";
import Overview from "./overview";

export default connect(
  (state) => ({
    token: getToken(state),
    mainHeading: getOverviewheading(state),
    userPicture: getUserPicture(state),
    sections: getOverviewSections(state),
    fetchedSummary: getFetchedSummary(state),
    listerScore: getUserListerScore(state),
    isPremiumEnabled: isPremiumEnabledSelector(state),
    pendingReviews: getPendingReviewsSelector(state),
    transactions: getTransactionsSummarySelector(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      getOverviewSummary: () =>
        dispatch(OverviewActions.getOverviewSummary(stateProps.token)),
      getListerScore: () =>
        dispatch(UserActions.getListerScore(stateProps.token)),
      resetOverview: () => dispatch(OverviewActions.resetOverview()),
      getPendingReviews: () => dispatch(getPendingReviewsAction()),
      getTransactions: () =>
        dispatch(TransactionsActions.getTransactions(stateProps.token)),
    };
  },
)(Overview);
