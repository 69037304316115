const BRAND_COLORS = {
  PRIMARY: {
    DEFAULT: "#04bf95",
    HOVER: "#04af88",
  },
  SECONDARY: {
    DEFAULT: "#6d8bff",
    HOVER: "#607ae0",
  },
  TERTIARY: {
    DEFAULT: "#1e3c71",
    HOVER: "#182f59",
  },
};

const SEMANTIC_COLORS = {
  SUCCESS: {
    DEFAULT: BRAND_COLORS.PRIMARY.DEFAULT,
    HOVER: BRAND_COLORS.PRIMARY.HOVER,
    LIGHTEST: "#e8fcf5",
  },
  WARNING: {
    DEFAULT: "#ff9900",
    HOVER: "#eb8d00",
    LIGHTEST: "#fff7ea",
  },
  DANGER: {
    DEFAULT: "#dd3636",
    HOVER: "#c22f2f",
    LIGHTEST: "#ffeeef",
  },
  INFO: {
    DEFAULT: "#489cff",
    HOVER: "#3a87e1",
    LIGHTEST: "#f0f7ff",
  },
};

const NEUTRAL_COLORS = {
  NEUTRAL_80: "#333333",
  NEUTRAL_50: "#7d7d7d",
  NEUTRAL_10: "#dddddd",
  NEUTRAL_05: "#f0f0f0",
  NEUTRAL_00: "#ffffff",
};

const ADDITIONAL_COLORS = {
  ADDITIONAL_01: "#68debf",
  ADDITIONAL_02: "#88b3d3",
  ADDITIONAL_03: "#b7d3f0",
  ADDITIONAL_04: "#fc9480",
  ADDITIONAL_05: "#fdc9b3",
  ADDITIONAL_06: "#ffc970",
  ADDITIONAL_07: "#f9f8f6",
  ADDITIONAL_08: "#ffe4a0",
  ADDITIONAL_09: "#fcf4e3",
  ADDITIONAL_10: "#f3f1e9",
  ADDITIONAL_11: "#5ddefa",
};

const GRADIENT_COLORS = {
  GRADIENT_01: `linear-gradient(270deg, #489cff 0%, #6d8bff 100%)`,
  GRADIENT_02: `linear-gradient(270deg, #ffe4a0 0%, #ffc970 100%)`,
};

export const BADI_COLORS = {
  ADDITIONAL: ADDITIONAL_COLORS,
  BRAND: BRAND_COLORS,
  GRADIENT: GRADIENT_COLORS,
  NEUTRAL: NEUTRAL_COLORS,
  SEMANTIC: SEMANTIC_COLORS,
};

export const getColorsObject = (colorCategory) => {
  const colorsObject = {};
  Object.keys(colorCategory).forEach((mainCategory) => {
    const category = colorCategory[mainCategory];
    const subCategories = Object.keys(category);
    subCategories.forEach((subCategory) => {
      colorsObject[`${mainCategory}_${subCategory}`] = category[subCategory];
    });
  });
  return colorsObject;
};

export const COLORS = {
  ...NEUTRAL_COLORS,
  ...ADDITIONAL_COLORS,
  ...getColorsObject(BRAND_COLORS),
  ...getColorsObject(SEMANTIC_COLORS),
};

/**
 * @deprecated Since version 2.39.0 - Use BADI_COLORS instead.
 */
export const colors = {
  darkGrey: NEUTRAL_COLORS.NEUTRAL_80,
  midGrey: NEUTRAL_COLORS.NEUTRAL_50,
  softGrey: NEUTRAL_COLORS.NEUTRAL_10,
  lightGrey: NEUTRAL_COLORS.NEUTRAL_05,
  white: NEUTRAL_COLORS.NEUTRAL_00,
  blue: "#0070f0",
  blueDark: "#005fcc",
  blueBooking: SEMANTIC_COLORS.INFO.DEFAULT,
  blueBookingDark: SEMANTIC_COLORS.INFO.HOVER,
  blueLight: SEMANTIC_COLORS.INFO.LIGHTEST,
  blueSky: "#5ddefa",
  blueSkyDark: "#2BC8EA",
  green: BRAND_COLORS.PRIMARY.DEFAULT,
  greenDark: BRAND_COLORS.PRIMARY.HOVER,
  irisBlue: BRAND_COLORS.SECONDARY.DEFAULT,
  orange: SEMANTIC_COLORS.WARNING.DEFAULT,
  orangeDark: SEMANTIC_COLORS.WARNING.HOVER,
  pink: "#ff3888",
  pinkDark: "#e03178",
  purple: "#943ffa",
  purpleDark: "#7c35d1",
  red: SEMANTIC_COLORS.DANGER.DEFAULT,
  redLight: SEMANTIC_COLORS.DANGER.LIGHTEST,
  redDark: SEMANTIC_COLORS.DANGER.HOVER,
  turquoise: "#00BFB3",
  turquoiseDark: "#00ADA3",
  yellow: "#fdbc30",
  yellowDark: "#f5b121",
  premiumText: "#27447e",
  // TODO - remove this color after removing the dependencies on web
  main: "#1e3255",
  tertiary: BRAND_COLORS.TERTIARY.DEFAULT,
  tertiaryHover: BRAND_COLORS.TERTIARY.HOVER,
};

/**
 * @deprecated Since version 2.39.0 - Use BADI_COLORS.BRAND.PRIMARY.DEFAULT instead.
 */
export const mainColor = BRAND_COLORS.PRIMARY.DEFAULT;

/**
 * @deprecated Since version 2.39.0 - Use BADI_COLORS.GRADIENT instead.
 */
export const gradient = {
  default: "linear-gradient(to right, #08c781, #00bfb3)",
  dark: "linear-gradient(to right, #07b172, #00ada3)",
};
