import { useState, useEffect } from "react";
import { fetchInbox } from "datamodel/Inbox/api";
import { getAPIUrl } from "base/shared/Utils";
import envVars from "base/shared/Env";
import { CONNECTION_STATUS_CODE } from "datamodel/Conversation/constants";
import fetch from "base/shared/fetch-data";
import { CHATS_PER_PAGE } from "./constants";
import { CURRENT_USER_ROLES } from "app/datamodel/Inbox/constants";

const getConnectionEnquiry = async (id, token) => {
  const { BADI_CONNECTION_ENQUIRY } = envVars();
  const serverUrl = getAPIUrl(BADI_CONNECTION_ENQUIRY.replace(":id", id));

  const response = await fetch(serverUrl, {
    token,
  });
  return response.data;
};

const fetchData = async ({ token, filters, pagination: { page } }) => {
  const { roomId, category } = filters;
  const result = await fetchInbox(
    { token },
    {
      page: page || 1,
      per: CHATS_PER_PAGE,
      current_user_role: CURRENT_USER_ROLES.LISTER,
      room: roomId,
      category,
    },
  );
  const { data, pagination } = result;
  const [enquiryPendingConnections, otherConnections] = data.connections.reduce(
    ([enquiryPendingConnections, otherConnections], connection) => {
      if (connection.status === CONNECTION_STATUS_CODE.ENQUIRY_PENDING) {
        return [[...enquiryPendingConnections, connection], otherConnections];
      } else {
        return [enquiryPendingConnections, [...otherConnections, connection]];
      }
    },
    [[], []],
  );
  const requests = await Promise.all(
    enquiryPendingConnections.map(async (connection) => {
      const enquiry = await getConnectionEnquiry(connection.id, token);
      return { connection, enquiry };
    }),
  );
  return {
    isLoaded: true,
    requests: {
      data: requests,
    },
    chats: {
      data: otherConnections,
    },
    pagination,
  };
};

const DEFAULT_PAGINATION = {
  total: 0,
  current_page: 1,
  total_pages: 1,
};

const fetchDataForInbox = (token, filters, pagination) => {
  const { roomId, category } = filters;
  const [data, setData] = useState({
    isLoaded: false,
    pagination: DEFAULT_PAGINATION,
  });
  useEffect(() => {
    fetchData({
      token,
      filters: { roomId, category },
      pagination,
    }).then((data) => {
      setData(data);
    });
  }, [roomId, category, pagination.page]);

  return data;
};

export { fetchDataForInbox };
