import styled, { css } from "styled-components";

import { baseStyles } from "../button/styled-components";

export const BaseLink = styled.a`
  ${baseStyles}

  text-decoration: none;

  ${({ fullWidth }) => !fullWidth && "display: inline-flex"};

  ${(props) => css`
    &:hover {
      cursor: pointer;
      background: ${props.hoverBackground || props.background};
      border: ${props.hoverBorder || "none"};
      text-decoration: none;
    }
  `}
`;
