import { chain, get, mapValues, shake, isEmpty } from "radash";
import { translate } from "base/shared/Localization";
import { getCurrencySymbol } from "base/shared/Utils";
import { getGender, getStudies } from "base/shared/User";
import { getGenderIconById } from "components/Icons/Gender";
import { getOccupationIconById } from "components/Icons/Occupation";
import { getOccupation } from "app/datamodel/Conversation/utils";
import { getOccupationString, getUserLanguages } from "base/shared/User";

const getTags = chain(
  (obj) => get(obj, "enquiry.user.tags"),
  (tags = {}) =>
    mapValues(tags, (entries, tagCategoryKey) => ({
      key: tagCategoryKey,
      title: translate(
        `profile.form.fields.tags.category.${tagCategoryKey}.name`,
      ),
      iconSrc: `/assets/icons/icon-${tagCategoryKey}.svg`,
      iconAlt: tagCategoryKey,
      entries,
    })),
  (tags) => shake(tags, ({ entries }) => isEmpty(entries)),
);

const InboxListerModel = {
  id: "connection.id",
  matchesStatusLabel: "enquiry.room.matches_status_label",
  hasBadiGold: "enquiry.room.premium_plan",
  payToMatchRequired: "enquiry.pay_to_match_required",
  user: {
    name: "enquiry.user.first_name",
    age: "enquiry.user.age",
    picture: "enquiry.user.cover_picture.width_500_url",
    picture_small: "enquiry.user.cover_picture.width_27_url",
    member_since: "enquiry.user.metrics.member_since",
    last_active: "enquiry.user.metrics.last_connection",
    tags: getTags,
  },
  room: {
    id: "enquiry.room.id",
    title: "enquiry.room.title",
    picture: "enquiry.room.cover_picture.width_100_url",
    price: chain(
      (obj) => [
        get(obj, "enquiry.room.monthly_price.price"),
        get(obj, "enquiry.room.monthly_price.currency"),
      ],
      ([price, currency]) => `${getCurrencySymbol(currency)}${price}`,
    ),
    billsIncluded: "enquiry.room.monthly_price.bills_included",
    address: "enquiry.room.address",
    availableFrom: "enquiry.room.available_from",
    minimumStay: "enquiry.room.min_days",
  },
  nextStep: "connection.next_step",
};

const InboxListerMobileModel = {
  id: "connection.id",
  matchesStatusLabel: "enquiry.room.matches_status_label",
  hasBadiGold: "enquiry.room.premium_plan",
  payToMatchRequired: "enquiry.pay_to_match_required",
  user: {
    name: "enquiry.user.first_name",
    age: "enquiry.user.age",
    genderIcon: chain(
      (obj) => get(obj, "enquiry.user.biological_sex"),
      getGender,
      (gender) => getGenderIconById(gender.value),
    ),
    bio: "enquiry.user.small_bio",
    occupationDescription: chain(
      (obj) => get(obj, "enquiry.user.occupation_detail"),
      getOccupation,
      getOccupationString,
    ),
    occupationIcon: chain(
      (obj) => get(obj, "enquiry.user.occupation_detail.main_occupation"),
      getOccupationIconById,
    ),
    picture: "enquiry.user.cover_picture.width_500_url",
    picture_small: "enquiry.user.cover_picture.width_27_url",
    tags: getTags,
    languages: chain(
      (obj) => get(obj, "enquiry.user.languages_by_affinity"),
      getUserLanguages,
      (langs) => langs.map(({ title }) => translate(title)),
      (langTitles) => langTitles.join(", "),
    ),
    studiesDescription: chain(
      (obj) =>
        get(obj, "enquiry.user.occupation_detail.pursued_level_of_study"),
      (levelOfStudy) => (levelOfStudy ? getStudies(levelOfStudy) : {}),
      ({ title }) => translate(title),
    ),
  },
  room: {
    id: "enquiry.room.id",
    title: "enquiry.room.title",
    address: "enquiry.room.address",
    picture: "enquiry.room.cover_picture.width_100_url",
  },
  nextStep: "connection.next_step",
};

export { InboxListerModel, InboxListerMobileModel };
