import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { fetchBadiPlusCollection } from "app/datamodel/Home/api";
import { Heading, Spacer, Text } from "@badi/badi-components";
import RoomCard from "./room-card";
import Slider from "app/components/SliderV2";
import { translate } from "base/shared/Localization";
import { BADI_COLORS } from "app/badi-components";
import styled from "styled-components";

const label = {
  backgroundColor: BADI_COLORS.BRAND.TERTIARY.DEFAULT,
  color: BADI_COLORS.NEUTRAL.NEUTRAL_00,
  content: "BADI PLUS",
};

const toRoomCardProps = (room) => ({
  ...room,
  priceText: room.price.displayText,
});

const LoadingState = styled.div`
  width: 100%;
  height: 245px;
`;

const FeaturedBadiPlus = ({ token }) => {
  const [rooms, setRooms] = useState([]);
  useEffect(() => {
    fetchBadiPlusCollection({ token }).then((collection) => {
      const collectionRooms = collection?.rooms || [];
      setRooms(collectionRooms.map(toRoomCardProps));
    });
  }, []);

  return (
    <Container>
      <Spacer top={5}>
        <Heading level="24-Bold">Badi Plus</Heading>
        <Spacer bottom={2}>
          <Text>
            {translate("home.collections.badi_homes.description")}{" "}
            <a
              href={translate("home.collections.badi_homes.url")}
              target="_blank"
            >
              <u>{translate("home.collections.badi_homes.url-text")}</u>
            </a>
          </Text>
        </Spacer>
        {rooms.length > 0 ? (
          <Slider>
            {rooms.map((room) => (
              <RoomCard key={room.id} {...room} />
            ))}
          </Slider>
        ) : (
          <LoadingState />
        )}
      </Spacer>
    </Container>
  );
};

export default FeaturedBadiPlus;
